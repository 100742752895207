import React, {useEffect} from 'react';
import {Route, Routes, BrowserRouter, Navigate} from 'react-router-dom';
import HomePage from "./pages/HomePage/HomePage";
import './App.css';
import "./styles/styles.scss";
import LoadingPage from "./pages/LoadingPage/LoadingPage";
import ReactPixel from "react-facebook-pixel";

const PIXEL_ID = "1071372291225718";

function App() {
  useEffect(() => {
    try {
      ReactPixel.init(PIXEL_ID);
    }
    catch (e) {
      console.log('qqqqq', e);
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/*<Route path="/" element={<Navigate to="/" replace />} />*/}
        {/*<Route path="/home" element={<HomePage />} />*/}
        <Route path="/" element={<LoadingPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

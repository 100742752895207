import React, {useEffect, useState} from 'react';
import styles from './LoadingPage.module.scss';
import LogoLoading from '../../assets/svg/PNG_Logo_Eng_Ukr.svg';
import GreenButton from "../../components/buttons/GreenButton/GreenButton";
import Modal from "../../components/modals/Modal";
import IconShare from '../../assets/svg/icon22.svg';
import {Link} from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

const LoadingPage = () => {
  const [isOpen, toggleModal] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const trackFacebook = () => {
    ReactPixel.trackCustom("openFacebook");
  }

  const trackInstagram = () => {
    try {

      ReactPixel.trackCustom("openInstagram");
    }
    catch (e) {
      console.log('eee', e);
    }
  }

  return <div className={styles.container}>
    <div className={styles.linksWrapper}>
      <Link className={styles.linkWrapper} to={'https://www.facebook.com/profile.php?id=61564299561261'} target={'_blank'} onClick={trackFacebook}>
        <p>Facebook</p>
        <img src={IconShare} alt={''}/>
      </Link>
      <Link to={'https://www.instagram.com/lehit.village'} className={styles.linkWrapper} target={'_blank'} onClick={trackInstagram}>
        <p>Instagram</p>
        <img src={IconShare} alt={''}/>
      </Link>
    </div>
    <div className={styles.wrapper}>
      <img src={LogoLoading} alt={''} width={windowWidth < 768 ? 47 : 89}/>
      <div className={styles.smallWrapper}>
        <p className={styles.text}>Coming soon...</p>
        <p className={styles.description}>Довіртесь леготу Карпат – і він приведе Вас до витоків спокою і краси.</p>
      </div>
      <GreenButton text={'Дізнатися більше'} onClick={toggleModal}/>
      <Modal isOpen={isOpen} toggleModal={toggleModal}/>
    </div>
  </div>
}

export default LoadingPage;

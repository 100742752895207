import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './ModalForm.scss';
import logo from '../../assets/images/logo-small.png';
import closeIcon from "../../assets/images/x.svg";
import classNames from "classnames";
import axios from "axios";

Modal.setAppElement('#root');

const ModalForm = ({isOpen, toggleModal}) => {
  // const [formData, setFormData] = useState({ name: '', phone: '' });
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [isSandedData, sendData] = useState(false);

  const closeModal = () => {
    setPhoneError('');
    setNameError('');
    setPhone('');
    setName('');
    toggleModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log()
    if(name.length < 3) {
      setNameError('Невірно введене ім`я.')
    } else if(!isValidPhoneNumber(phone)) {
      setPhoneError('Невірно введений номер.')
    } else {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('phone', phone);
      formData.append('token', 'x8IFhPMul6uAJ65yNUnOfM3Di9x0YTV874fGXOoY4kc');
      formData.append('partner_id', '19339');
      formData.append('action', 'partner-custom-form');
      try {
        const response = await axios.post("https://crm.g-plus.app/api/actions", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('aaaa', response);
        if (response.data.success) {
          sendData(true);
        } else {
          setPhoneError("Щось пішло не так. Спробуйте пізніше.");
        }
      } catch (error) {
        setPhoneError("Помилка при відправці даних: " + error.message);
      }
    }
  };

  useEffect(() => {
    isSandedData && setTimeout(() => {
      setName("");
      setPhone("");
      closeModal();
    }, 10000);
  }, [isSandedData]);

  const handleChange = (field, value) => {
    if(field === 'name') {
      setNameError('');
      setName(value);
    } else {
      setPhoneError('');
      setPhone(value);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="modal-content"
        overlayClassName="modal-overlay"
        backdrop={true}
      >
        <button onClick={closeModal} className="close-modal-btn">
          <img src={closeIcon} alt="CloseIcon"/>
        </button>
        { isSandedData ? <div className="modal-header">
            <img src={logo} alt="Logo" className="modal-logo"/>
            <h2 className="modal-header__title">Дякуємо!</h2>
            <p className="modal-header__sub">Ваша заявка успішно надіслана. Наш менеджер зв'яжеться з вами найближчим
              часом.</p>
          </div>
          :
          <>
            <div className="modal-header">
              <img src={logo} alt="Logo" className="modal-logo"/>
              <h2 className="modal-header__title">Залишіть заявку</h2>
              <p className="modal-header__sub">Ми зв'яжемося з вами найближчим часом</p>
            </div>
            <form onSubmit={handleSubmit} className="modal-form">
              <label>Ваше ім’я</label>
              <input
                type="text"
                value={name}
                onChange={(e) => handleChange('name', e.target.value)}
                placeholder="Олена Грицюк"
                className={`input-name ${!!nameError ? 'error' : ''}`}
              />
              {!!nameError && <span className="error-message">{nameError}</span>}

              <div className="input-phone">
                <label>Номер телефону</label>
                <PhoneInput
                  defaultCountry="UA"
                  value={phone}
                  onChange={(value) => handleChange('phone', value)}
                  placeholder="+38 (063) 888 44 88"
                  className={classNames(`input`, {'error': phoneError})}
                />
                {!!phoneError && <span className="error-message">{phoneError}</span>}
              </div>
              <button type="submit" className="submit-btn">Залишити заявку</button>
            </form>
          </>}
      </Modal>
    </>
  );
};

export default ModalForm;
